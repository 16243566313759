import React from "react"
import { graphql } from "gatsby"
import { cleanHTML } from "../utils/Utils"
import Page from "../components/page/Page"

export default props => {
  let bioEdge = props.data.allContentfulBio.edges[0]
  return (
    <Page class="bio" slug="bio" title="Bio">
      <article className="article">
        <header className="article-header">
          <h1 className="title">Bio</h1>
        </header>
        <main
          className="article-main"
          dangerouslySetInnerHTML={{
            __html: cleanHTML(bioEdge.node.texto.childMarkdownRemark.html),
          }}
        />
      </article>
    </Page>
  )
}

export const bioQuery = graphql`
  query {
    allContentfulBio {
      edges {
        node {
          texto {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
